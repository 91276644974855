import * as React from "react"
import { Page } from "../components"
import Img from "gatsby-image"
import { graphql, useStaticQuery} from "gatsby";

const page = {
  backgroundColor: "#F7F8F9",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const alert = {
  backgroundColor: "white",
  display: "inline-block",
  padding: "60px 15px",
  borderRadius: "10px",
  width: "90%",
}

const titulo = {
  fontFamily: "Arial, Helvetica, sans-serif",
  fontSize: "26px",
  textAlign: "center",
  margin: "auto"
}

const bar = {
  margin: "20px auto",
  height: "4px",
  maxWidth: "220px",
  backgroundColor: "#F8710E",
}

const leyenda = {
  color: "#022358",
  fontFamily: "Arial, Helvetica, sans-serif",
  fontSize: "16px",
  display: "block",
  width: "100%",
  textAlign: "center",
}

const imagenCono = {
  width: "60px",
  margin: "auto",
  marginTop: "40px",
}

const imagenLogo = {
  width: "80px",
  position: "absolute",
  bottom: "20px",
}

// markup
const IndexPage = () => {


      //Image

    const image = useStaticQuery(graphql`
        query {
            cono: file(relativePath: { eq: "cone.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            logo: file(relativePath: { eq: "logo.jpeg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)


  return (
      <div style={page}>
        <div style={alert}>
            <div style={titulo}>
              ¡Hola!
            </div>
            <div style={bar}></div>
            <span style={leyenda}>
                Este sitio web está en construcción. ¡Regresa pronto!
            </span>
            <Img style={imagenCono} fluid={image.cono.childImageSharp.fluid} />
        </div>

          <Img style={imagenLogo} fluid={image.logo.childImageSharp.fluid} />

      </div>
  )
}

export default IndexPage
